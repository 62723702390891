$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue:    #007bff !global;
//$blue: #33bcf1 !global;
$indigo: #6610f2 !global;
$purple: #6f42c1 !global;
$pink: #e83e8c !global;
$red: #c1282d !global;
$orange: #fd7e14 !global;
$yellow: #ffc107 !global;
$green: #28a745 !global;
$teal: #20c997 !global;
$cyan: #17a2b8 !global;

$border-radius:               0 !global;
$border-radius-lg:            0 !global;
$border-radius-sm:            0 !global;

$primary: $red !global;
$secondary: $gray-600 !global;
$success: $green !global;
$info: $blue !global;
$warning: $yellow !global;
$danger: $red !global;
$light: $gray-100 !global;
$dark: $gray-800 !global;