@font-face {
    font-family: 'primary';
    src: url(../fonts/primary.eot),
    url(../fonts/primary.eot?#iefix) format('embedded-opentype'),
    url(../fonts/primary.woff2) format('woff2'),
    url(../fonts/primary.woff) format('woff'),
    url(../fonts/primary.ttf) format('truetype'),
    url(../fonts/primary.svg) format('svg');
}

@font-face {
    font-family: 'secondary';
    src: url(../fonts/secondary.eot),
    url(../fonts/secondary.eot?#iefix) format('embedded-opentype'),
    url(../fonts/secondary.woff2) format('woff2'),
    url(../fonts/secondary.woff) format('woff'),
    url(../fonts/secondary.ttf) format('truetype'),
    url(../fonts/secondary.svg) format('svg');
}