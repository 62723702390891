.store{
    position: relative;
    border-radius: 50px;
    overflow: hidden;

    .store-links{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        background: fade-out($color: #fff, $amount: 0.3);
        margin: 0;
        visibility: hidden;
        opacity: 0;
        transition: .3s ease;

        img{
            width: 100%;
        }
    }

    &:hover{
        .store-links{
            visibility: visible;
            opacity: 1;
        }
    }
}