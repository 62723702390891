header{
    nav{
        li{
            a.active{
                text-decoration: underline;
            }
        }
    }
}

body{
    font-size: 22px;
    background: $gray-200;
    font-family: secondary, sans-serif;
}