.font-primary{
    font-family: primary;
}

.font-secondary{
    font-family: secondary;
    font-weight: 600;
    letter-spacing: 2px;
}

.font-md{
    font-size: 20px;
}

.font-lg{
    font-size: 24px;
}

.font-xl{
    font-size: 30px;
}

.font-xxl{
    font-size: 36px;
}

.font-xxxl{
    font-size: 42px;
}

.font-xxxxl{
    font-size: 48px;
}

.font-xxxxxl{
    font-size: 54px;
}

.font-xxxxxxl{
    font-size: 60px;
}

a{
    color: $red!important;
}